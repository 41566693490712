import React from 'react';
import GdprRequestFormEn from '../../components/GdprRequestFormEn';
import Logo from '../../components/Logo';
import FooterLogo from '../../components/FooterLogo';
import Contact from '../../components/Contact';
import withIntl from '../../i18n/withIntl';

import '../../../assets/styles/Web.scss';

const GdprEn = () => (
  <>
    <section className="Section Section--contact Section--themeLight">
      <div className="Section-main">
        <Logo />
        <h2 className="Section-mainTitle">WE WOULD LIKE TO STAY IN TOUCH WITH YOU</h2>
        <p className="Section-text">
          Your personal data is the only possible connection for us and we appreciate it. By filling
          in the information below, we will send you an e-mail with a detailed description of the
          rules of our symbiosis according to the General Data Protection Regulation (GDPR).
        </p>
        <GdprRequestFormEn />
      </div>
    </section>
    <footer className="Footer">
      <FooterLogo />
      <Contact />
    </footer>
  </>
);

export default withIntl(GdprEn, 'en');
